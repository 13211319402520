<template>
  <div>
    <v-autocomplete
      ref="inputField"
      v-bind="$attrs"
      outlined
      :items="CountrySelection"
      v-model="model"
      :rules="[rules.required]"
      :item-value="code ? 'code' : 'name'"
      :filter="filter"
      :label="$translation.t('Country')"
      name="country"
      auto-select-first
      hide-details="auto"
    >
      <template v-slot:selection="data">
        <div class="d-flex align-items-center">
          <span class="mr-3">
            {{ data.item.flag }}
          </span>
          {{ $translation.t(data.item.name) }}
        </div>
      </template>
      <template v-slot:item="data">
        <div class="d-flex align-items-center">
          <span class="mr-3">
            {{ data.item.flag }}
          </span>
          {{ $translation.t(data.item.name) }}
        </div>
      </template>
    </v-autocomplete>
    <state-select-field
      class="th-mt-12"
      v-bind="$attrs"
      v-if="showState"
      :code="code"
      hide-if-no-selection
      :global="global"
      :country="model"
      v-model="stateModel"
    />
  </div>
</template>

<script>
import rules from "@/utils/inputRules";
import CountrySelection from "../../../data/countries.json";
import { escapeRegExp } from "lodash";
import StateSelectField from "@/components/FormFields/GeneralInfoFields/StateSelectField";

export default {
  name: "CountryInputField",
  components: { StateSelectField },
  props: {
    value: String,
    code: Boolean,
    showState: Boolean,
    state: String,
    global: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    rules,
    CountrySelection,
    model: "",
    stateModel: "",
  }),
  created() {
    this.model = this.findCountry(this.value);

    if (this.showState) this.stateModel = this.state || "";
  },
  methods: {
    filter(item, queryText) {
      const q = new RegExp(escapeRegExp(queryText.toLowerCase()));
      return (
        item?.name?.toLowerCase().match(q) ||
        this.$translation.t(item?.name)?.toLowerCase().match(q) ||
        item?.flag?.toLowerCase().match(q)
      );
    },
    findCountry(countryValue) {
      if (!countryValue) return countryValue;

      const country = CountrySelection.find(
        ({ name, code }) => name === countryValue || code === countryValue
      );

      if (!country) return countryValue;

      return country[this.code ? "code" : "name"];
    },
  },
  watch: {
    model(newCountry) {
      this.$emit("input", newCountry);
    },
    stateModel(newState) {
      this.$emit("update:state", newState);
    },
    value(newCountry) {
      this.model = newCountry;
    },
  },
};
</script>

<style scoped></style>
