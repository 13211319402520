<template>
  <div class="th-mb-24">
    <h5
      class="th-m-0 body2"
      v-if="input.label"
      v-html="$translation.t(input.label)"
    ></h5>
    <v-form ref="newCompanyForm">
      <div class="d-flex align-items-start">
        <v-text-field
          class="th-mx-4"
          :label="$translation.t('Company name')"
          outlined
          v-model="companyName"
          hide-details="auto"
          :rules="[rules.required]"
        />
        <country-input-field v-model="companyCountry" />
        <!--        <v-autocomplete-->
        <!--          class="th-mx-4"-->
        <!--          outlined-->
        <!--          :items=""-->
        <!--          v-model="companyCountry"-->
        <!--          auto-select-first-->
        <!--          :label="$translation.t('Company country')"-->
        <!--          hide-details="auto"-->
        <!--          :key="subinputKey"-->
        <!--          :rules="subinput.rules || []"-->
        <!--        />-->
        <v-btn
          color="primary"
          outlined
          size="large"
          @click="addOption"
          height="48"
          class="th-ml-12"
        >
          {{ $translation.t("Add") }}
        </v-btn>
      </div>
    </v-form>

    <div class="my-5" v-if="model.length > 0">
      <v-data-table
        :hide-default-footer="true"
        :headers="headers"
        :items="model"
        class="elevation-1"
      >
        <template v-slot:item.action="{ item }">
          <v-icon small @click="remove(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import rules from "../../../../utils/inputRules";
import CountryInputField from "../../../FormFields/GeneralInfoFields/CountryInputField";

export default {
  name: "CompanyNameCountryList",
  components: { CountryInputField },
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: [Array, String],
  },
  data: () => ({
    rules,
    model: [],
    newItemModel: {},
    options: [],
    newOption: "",
    companyName: "",
    companyCountry: "",
  }),
  mounted() {
    this.model = this.value || [];
  },
  computed: {
    headers() {
      return [
        {
          text: this.$translation.t("Company branch name"),
          value: "name",
        },
        {
          text: this.$translation.t("Company branch country"),
          value: "country",
        },
        { text: "", value: "action", align: "end" },
      ];
    },
  },
  methods: {
    addOption() {
      if (!this.$refs.newCompanyForm.validate()) return;

      this.model.push({
        name: this.companyName,
        country: this.$translation.t(this.companyCountry),
      });

      this.reset();
      this.$refs.newCompanyForm.reset();
    },
    remove(item) {
      this.model = this.model.filter(
        (entry) =>
          Object.keys(item).filter((key) => entry[key] === item[key]).length ===
          0
      );
    },
    reset() {
      this.companyName = "";
      this.companyCountry = "";
    },
    validate() {
      this.addOption();
      return true;
    },
  },
  watch: {
    model: {
      handler: function (newInput) {
        this.$emit("input", newInput);
      },
      deep: true,
    },
  },
};
</script>
